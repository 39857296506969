$dark: #222d37;
$battleship-grey: #747679;
$light-grey-blue-two: #babbbc;
$light-periwinkle: #dcddde;
$pale-lilac-two: #eaeaeb;
$SVHA-blue: #005c9c;
$cool-blue: #408cc4;
$light-grey-blue: #80b3d8;
$background-blue: #f1f7ff;
$white: #ffffff;
$SVHA-purple: #3b2c78;
$deep-lavender: #7763ad;
$pale-purple: #a497c9;
$pale-lilac: #e1d8f3;
$green-blue: #01b092;
$background-aqua: #a4e3d8;
$ice: #e3f8f5;
$cerise: #dc0650;
$blue: #4954A7;
$link-blue: #0167b1;
$font-dark: #202020;
